<template>
  <main class="login_area">
    <h1>로그인</h1>
    <ValidationObserver v-slot="{ invalid, untouched }">
      <form @submit="onSubmit" @reset="onReset">
        <ValidationProvider name="아이디" rules="required" v-slot="{ errors }">
          <input
            type="text"
            v-model="form.loginid"
            autocapitalize="off"
            autocomplete="off"
            :disabled="submitStatus === 'PENDING'"
            placeholder="아이디 또는 이메일주소"
            :class="{ active: errors[0] }"
          />
          <span>{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          name="비밀번호"
          rules="required"
          v-slot="{ errors }"
        >
          <input
            type="password"
            v-model="form.pass"
            autocapitalize="off"
            autocomplete="off"
            placeholder="비밀번호"
            :disabled="submitStatus === 'PENDING'"
            :class="{ active: errors[0] }"
          />
          <span>{{ errors[0] }}</span>
        </ValidationProvider>
        <!-- <input type="checkbox" v-model="form.saveLoginid" />
        <label for="save_login">아이디 저장</label> -->
        <div class="btn_100">
          <base-button
            type="primary-lg"
            nativeType="submit"
            :disabled="invalid || submitStatus === 'PENDING' || untouched"
            >{{
              submitStatus === "PENDING" ? "로그인중.." : "로그인"
            }}</base-button
          >
        </div>
      </form>
    </ValidationObserver>
    <div class="login_reg">
      <!-- <router-link to="">아이디 찾기</router-link><span>|</span> -->
      <p @click="confirm('비밀번호 찾기')">비밀번호 찾기</p>
      <span>|</span>
      <p @click="confirm('회원가입')">회원가입</p>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import Dialog from "@/components/Dialog.vue";
import storage from "@/common/storage";
import { ID_TOKEN_KEY, ID_USER_KEY } from "@/constants";
// import { naverLoginService } from "@/assets/naver.login.service";

export default {
  name: "Login",
  components: {
    BaseButton,
  },
  data() {
    return {
      submitStatus: null, // ERROR, PENDING, OK

      form: {
        loginid: "",
        pass: "",
        saveLoginid: false,
      },
    };
  },
  created() {
    this.$Progress.start();

    if (this.getSaveLoginid) {
      this.form.saveLoginid = true;
      this.form.loginid = this.getSaveLoginid;
    }
  },
  mounted() {
    this.$Progress.finish();
    this.submitStatus = null;
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getSaveLoginid"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    confirm(type) {
      if (this.form.loginid != "" || this.form.pass != "") {
        this.$modal.show(
          Dialog,
          {
            type: "confirm",
            title: "알림",
            content: `로그인 하지 않고 ${type} 페이지로 이동 하시겠습니까?`,
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-close": (response) => {
              if (response.params.confirm) {
                if (type == "비밀번호 찾기") {
                  this.$router.push({ name: "Recover" });
                } else if (type == "회원가입") {
                  this.$router.push({ name: "Register" });
                }
              }
            },
          }
        );
      } else {
        if (type == "비밀번호 찾기") {
          this.$router.push({ name: "Recover" });
        } else if (type == "회원가입") {
          this.$router.push({ name: "Register" });
        }
      }
    },
    async onSubmit(evt) {
      evt.preventDefault();
      this.submitStatus = "PENDING";
      let idleTime = 0;

      this.$store
        .dispatch("login", {
          loginid: this.form.loginid,
          pass: this.form.pass,
          saveLoginid: this.form.saveLoginid,
        })
        .then((response) => {
          this.submitStatus = "OK";

          console.log(response);
          if (response.status == 200) {
            this.$router.push({
              name: "Mypage",
            });
            const logoutTimer = setInterval(function () {
              idleTime = idleTime + 1;
              console.log(idleTime);
              if (idleTime > 59) {
                console.log("time to logout");
                storage.remove(ID_TOKEN_KEY);
                storage.remove(ID_USER_KEY);
                window.location.href = window.location;
                clearInterval(logoutTimer);
              }
            }, 60000);
          }
        })
        .catch((message) => {
          this.submitStatus = "ERROR";
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: message,
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              minWidth: 300,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
        });
      return true;
    },
    // 취소
    onReset(evt) {
      evt.preventDefault();
      /*
      this.$router.push({
        name: "AuthTerms"
      });
      */
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
